//
import Dacha2 from "@/assets/img/portfolio/dacha/2.jpg";
import Dacha3 from "@/assets/img/portfolio/dacha/3.jpg";
import Dacha4 from "@/assets/img/portfolio/dacha/4.jfif";
import Dacha5 from "@/assets/img/portfolio/dacha/5.jpeg";
import Dacha6 from "@/assets/img/portfolio/dacha/6.jfif";

//
import Karasi2 from "@/assets/img/portfolio/karasi/2.jpg";
import Karasi3 from "@/assets/img/portfolio/karasi/3.jpg";
import Karasi4 from "@/assets/img/portfolio/karasi/4.jpg";
import Karasi5 from "@/assets/img/portfolio/karasi/5.jpg";
import Karasi6 from "@/assets/img/portfolio/karasi/6.jpg";

//
import Mamontovka2 from "@/assets/img/portfolio/mamontovka/2.jpg";
import Mamontovka3 from "@/assets/img/portfolio/mamontovka/3.jpg";
import Mamontovka4 from "@/assets/img/portfolio/mamontovka/4.jpg";
import Mamontovka5 from "@/assets/img/portfolio/mamontovka/5.jpg";
import Mamontovka6 from "@/assets/img/portfolio/mamontovka/6.jpeg";

//
import Semya2 from "@/assets/img/portfolio/semya/2.jpg";
import Semya3 from "@/assets/img/portfolio/semya/3.jpg";
import Semya4 from "@/assets/img/portfolio/semya/4.jpg";
import Semya5 from "@/assets/img/portfolio/semya/5.jpg";
import Semya6 from "@/assets/img/portfolio/semya/6.jpg";

export const PHOTOS = [
  {
    id: "dacha",
    images: [Dacha2, Dacha3, Dacha4, Dacha5, Dacha6],
  },

  {
    id: "karasi",
    images: [Karasi2, Karasi3, Karasi4, Karasi5, Karasi6],
  },

  {
    id: "mamontovka",
    images: [Mamontovka2, Mamontovka3, Mamontovka4, Mamontovka5, Mamontovka6],
  },

  {
    id: "semya",
    images: [Semya2, Semya3, Semya4, Semya5, Semya6],
  },
];
