<template>
  <div class="proection-garden">
    <div class="proection-garden__card proection-garden__card_desktop">
      <div class="proection-garden__info">
        <img class="proection-garden__icon" :src="Leaf" width="64" />
        <p class="proection-garden__title">Проектирование сада</p>

        <p class="proection-garden__text">
          {{ text1 }}
        </p>
        <p class="proection-garden__text">
          {{ text2 }}
        </p>
        <p class="proection-garden__text">
          {{ text3 }}
        </p>
        <p class="proection-garden__text">
          {{ text4 }}
        </p>
        <p class="proection-garden__text">
          {{ text5 }}
        </p>
      </div>

      <div class="proection-garden__photos proection-garden__photos_desktop">
        <img
          class="proection-garden__logo"
          :src="MAINLOGO"
          alt="logo"
          width="120"
          @click="$emit('click', 'main')"
        />

        <div class="proection-garden__images">
          <img class="proection-garden__image" :src="OneBefore" alt="" />
          <img class="proection-garden__image" :src="OneAfter" alt="" />
        </div>
      </div>
    </div>

    <!-- mobile -->

    <div class="proection-garden__card proection-garden__card_mobile">
      <div class="proection-garden__info proection-garden__info_mobile">
        <img class="proection-garden__icon" :src="Leaf" width="64" />
        <p class="proection-garden__title">Проектирование сада</p>

        <p class="proection-garden__text">
          {{ text1 }}
        </p>
        <p class="proection-garden__text">
          {{ text2 }}
        </p>
        <p class="proection-garden__text">
          {{ text3 }}
        </p>
        <p class="proection-garden__text">
          {{ text4 }}
        </p>
        <p class="proection-garden__text">
          {{ text5 }}
        </p>
      </div>

      <div class="proection-garden__photos proection-garden__photos_mobile">
        <img
          class="proection-garden__logo"
          :src="MAINLOGO"
          alt="logo"
          width="80"
          @click="$emit('click', 'main')"
        />

        <div class="proection-garden__images">
          <img class="proection-garden__image" :src="OneBefore" alt="" />
          <img class="proection-garden__image" :src="OneAfter" alt="" />
        </div>
      </div>
    </div>

    <Button class="proection-garden__button" />
  </div>
</template>

<script>
import Leaf from "@/assets/img/leaf.svg";
import MAINLOGO from "@/assets/img/MAINLOGO.png";
import Button from "@/components/Button.vue";

import OneBefore from "@/assets/img/proection-1.jpg";
import OneAfter from "@/assets/img/proection-2.jpg";

export default (await import("vue")).defineComponent({
  name: "ProectionGarden",

  components: { Button },

  data: () => ({
    id: "proection",

    Leaf,
    MAINLOGO,

    OneBefore,
    OneAfter,

    text1: `Чтобы сад выглядел стильно, был комфортен и соответствовал\nвкусам и образу жизни хозяев, нужен проект. Это первый этап\nсоздания сада. Проект дает представление, как будет выглядеть\nбудущий сад и помогает спланировать работы по его строительству.`,
    text2: `Проектирование состоит из эскизного проекта и рабочей\nдокументации.`,
    text3: `Эскизный проект - разрабатывается план сада, его стилистика,\nделается визуализация 3D.`,
    text4: `Рабочая документация - разрабатываются все необходимые\nчертежи для создания сада.`,
    text5: `Перед началом проектирвоания необходим выезд на объект и\nвстреча с заказчиком.`,
  }),
});
</script>

<style lang="scss" scoped>
.proection-garden {
  background-image: url("../assets/img/garden.png");
  background-size: cover;

  display: grid;

  &__card {
    grid-column: 1 / -1;
    display: grid;
  }

  &__title {
    margin-top: 16px;
    color: $proection-title;
    font: {
      size: 24px;
      weight: 700;
    }
    line-height: 32px;
  }
  &__text {
    text-align: center;
    margin-top: 16px;
    color: $proection-text;
    font: {
      size: 16px;
      weight: 500;
    }
    line-height: 24px;
    white-space: pre-wrap;
  }
  &__images {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    gap: 8px;
  }

  &__image {
    &:nth-child(1) {
      // transform: translateY(30px) translateX(30px);
    }
    border-radius: 16px;
    width: 280px;
    height: 400px;
    object-fit: cover;
    box-shadow: 0px 8px 4px 1px rgba($color: #000000, $alpha: 0.25);
  }
}

@media screen and (min-width: $min-laptop-lg) {
  .proection-garden {
    grid-template-columns: $grid-laptop;
    padding-bottom: 24px;
    min-height: 100vh;

    position: relative;

    &__icon {
      width: 100px;
    }
    &__title {
      margin-top: 32px;
    }

    &__text {
      font-size: 20px;
      line-height: 20.5px;
    }

    &__logo {
      right: 20px;
      top: 20px;
      position: absolute;
    }

    &__image {
      width: 280px;
      height: 400px;
    }

    &__card {
      grid-template-columns: $grid-laptop;
      &_mobile {
        display: none;
      }
    }

    &__info {
      grid-column: 1 / 8;
      display: flex;
      padding: 32px 0px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      &_mobile {
        display: none;
      }
    }
    &__photos {
      grid-column: 8 / -1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      padding: 24px;
      gap: 16px;
      margin-top: 64px;

      &_mobile {
        display: none;
      }
    }
    &__button {
      grid-column: 5 / 9;
    }
  }
}

@media screen and (min-width: $min-laptop) and (max-width: $max-laptop) {
  .proection-garden {
    grid-template-columns: $grid-laptop;
    padding-bottom: 24px;
    min-height: 100vh;

    &__image {
      width: 200px;
      height: 350px;
    }

    &__card {
      grid-template-columns: $grid-laptop;
      &_mobile {
        display: none;
      }
    }

    &__info {
      grid-column: 1 / 8;
      display: flex;
      padding: 32px 0px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      &_mobile {
        display: none;
      }
    }
    &__photos {
      grid-column: 8 / -1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column;
      padding: 24px;
      gap: 16px;

      &_mobile {
        display: none;
      }
    }
    &__button {
      grid-column: 5 / 9;
    }
  }
}

@media screen and (min-width: $min-tablet) and (max-width: $max-tablet) {
  .proection-garden {
    grid-template-columns: $grid-mobile;
    padding: 16px;

    &__card {
      background-color: rgba($color: $white, $alpha: 0.1);
      box-shadow: 0px 8px 10px 1px rgba($color: #000000, $alpha: 0.25);
      border-radius: 8px;
      padding-bottom: 16px;

      grid-template-columns: $grid-mobile;
      &_desktop {
        display: none;
      }
    }

    &__logo {
      display: none;
    }

    &__info {
      grid-column: 1 / -1;
      display: flex;
      padding: 32px 8px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      &_desktop {
        display: none;
      }
    }
    &__photos {
      grid-column: 1 / -1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column;

      &_desktop {
        display: none;
      }
    }
    &__button {
      margin-top: 32px;
      grid-column: 1 / -1;
    }
    &__images {
      width: 100%;
      justify-content: center;
      padding-bottom: 32px;
    }
    &__image {
      width: 120px;
    }
    &__image {
      width: 200px;
      height: 350px;
    }
  }
}

@media screen and (min-width: $min-mobile) and (max-width: $max-mobile) {
  .proection-garden {
    grid-template-columns: $grid-mobile;
    padding: 16px;

    &__card {
      background-color: rgba($color: $white, $alpha: 0.1);
      box-shadow: 0px 8px 10px 1px rgba($color: #000000, $alpha: 0.25);
      border-radius: 8px;
      padding-bottom: 16px;

      grid-template-columns: $grid-mobile;
      &_desktop {
        display: none;
      }
    }

    &__logo {
      display: none;
    }

    &__info {
      grid-column: 1 / -1;
      display: flex;
      padding: 32px 8px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      &_desktop {
        display: none;
      }
    }
    &__photos {
      grid-column: 1 / -1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column;

      &_desktop {
        display: none;
      }
    }
    &__button {
      margin-top: 32px;
      grid-column: 1 / -1;
    }
    &__images {
      width: 100%;
      justify-content: flex-start;
      padding-bottom: 32px;
    }
    &__image {
      width: 120px;
    }
    &__image {
      &:nth-child(1) {
        // transform: translateY(15px) translateX(15px);
      }
      width: 180px;
      height: 300px;
    }
  }
}
</style>
