<template>
  <div>
    <Main @click="navigateTo" id="main" />
    <About id="about" @click="navigateTo" />
    <Portfolio id="portfolio" @click="navigateTo" />
    <ProectionGarden id="projection" @click="navigateTo" />
    <Blago id="blago" @click="navigateTo" />
    <Green id="green" @click="navigateTo" />
    <Care id="care" @click="navigateTo" />
    <Contacts id="contacts" @click="navigateTo" />

    <Form />
  </div>
</template>

<script>
import About from "./sections/About.vue";
import Blago from "./sections/Blago.vue";
import Care from "./sections/Care.vue";
import Contacts from "./sections/Contacts.vue";
import Green from "./sections/Green.vue";
import Main from "./sections/Main.vue";

import Portfolio from "./sections/Portfolio.vue";
import ProectionGarden from "./sections/ProectionGarden.vue";

import Form from "./components/Form.vue";

export default {
  name: "App",

  components: {
    About,
    Portfolio,
    ProectionGarden,
    Blago,
    Green,
    Care,
    Contacts,
    Form,
    Main,
  },

  methods: {
    navigateTo(id) {
      const el = document.getElementById(id);
      el?.scrollIntoView({ behavior: "smooth", block: "start" });
    },
  },
};
</script>

<style scoped></style>
