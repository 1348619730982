import { createPinia } from "pinia";

import "./index.css";

import { createApp } from "vue";
import App from "./App.vue";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia).mount("#app");
