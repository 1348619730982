<template>
  <div type="button" class="button" @click="openModal">
    <Icon name="phone" />
    <p class="button__text">ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ</p>
  </div>
</template>

<script setup>
import Icon from "@/components/Icon.vue";

import { useFormStore } from "../store/form.store";

const formStore = useFormStore();

const openModal = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile =
    /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
      userAgent
    );

  if (!isMobile) {
    formStore.openForm();
  } else {
    const link = document.createElement("a");
    link.href = "tel:+79254682965";
    link.style.display = "none";
    link.click();
  }
};
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  background-color: $button-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 12px 16px;

  cursor: pointer;

  gap: 16px;
  outline: none;
  border: none;
  border-radius: 64px;

  &__text {
    color: $white;
    font: {
      size: 16px;
      weight: 600;
    }
    line-height: 24px;
    text-decoration: none;
  }
}
</style>
