<template>
  <div class="about"></div>
</template>

<script>
export default (await import("vue")).defineComponent({
  name: "About",
});
</script>

<style lang="scss" scoped>
.about {
  display: grid;
  justify-content: flex-end;
  align-items: flex-start;
}
</style>
