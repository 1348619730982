<template>
  <div class="portfolio">
    <div class="portfolio__header">
      <img
        src="../assets/img/greenlogo.png"
        class="portfolio__logo"
        alt="logo"
        @click="$emit('click', 'main')"
      />
      <p class="portfolio__title">ПОРТФОЛИО</p>
    </div>

    <div class="portfolio__images">
      <img
        class="portfolio__image one"
        :src="AfterOne"
        alt="before"
        @click="openModal('dacha')"
      />
      <img
        class="portfolio__image two"
        :src="BeforeOne"
        alt="before"
        @click="openModal('karasi')"
      />
    </div>

    <div class="portfolio__images2">
      <img
        class="portfolio__image three"
        :src="BeforeTwo"
        alt="before"
        @click="openModal('mamontovka')"
      />
      <img
        class="portfolio__image four"
        :src="AfterTwo"
        alt="before"
        @click="openModal('semya')"
      />
    </div>

    <Button class="portfolio__button" />

    <Modal ref="modal">
      <BaseCarousel :id="id" />
    </Modal>
  </div>
</template>

<script>
import AfterOne from "@/assets/img/portfolio/dacha/1.jpg";
import BeforeOne from "@/assets/img/portfolio/karasi/1.jpg";
import BeforeTwo from "@/assets/img/portfolio/mamontovka/1.jpg";
import AfterTwo from "@/assets/img/portfolio/semya/1.jpg";

import BaseCarousel from "@/components/BaseCarousel.vue";
import Button from "@/components/Button.vue";
import ContactPanel from "@/components/ContactPanel.vue";
import Modal from "@/components/Modal.vue";

export default (await import("vue")).defineComponent({
  name: "Portfolio",

  components: { Button, ContactPanel, Modal, BaseCarousel },

  data: () => ({
    BeforeOne,
    AfterOne,
    BeforeTwo,
    AfterTwo,

    id: "",
  }),

  methods: {
    openModal(id) {
      this.id = id;

      this.$refs.modal.openModal();
    },
  },
});
</script>

<style lang="scss" scoped>
.portfolio {
  padding: 8px;

  &__logo {
    width: 80px;
  }
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    grid-column: 1 / -1;
  }
  &__title {
    font: {
      size: 24px;
      weight: 600;
    }
    line-height: 24px;
    color: $button-bg;
  }
  &__images {
    margin-top: 32px;
  }
  &__images2,
  &__images {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__image {
    border-radius: 16px;
    cursor: pointer;
  }
}
@media screen and (min-width: $min-laptop-lg) {
  .portfolio {
    background-image: url("../assets/img/back2.png");

    background-size: 100%;
    background-position: center;

    min-height: 100vh;

    position: relative;

    &__header {
      padding-left: 128px;
      padding-top: 16px;
    }

    &__logo {
      left: 20px;
      top: 20px;
      width: 120px;
      position: absolute;
    }

    &__images,
    &__images2 {
      gap: 96px;
    }

    &__images2 {
      transform: translate(-80px, -50px);
    }

    &__image {
      width: 500px;
      max-height: 300px;
    }

    &__contact-panel,
    &__button {
      display: none;
    }
  }
}

@media screen and (min-width: $min-laptop) and (max-width: $max-laptop) {
  .portfolio {
    background-image: url("../assets/img/back2.png");

    background-size: 100%;
    background-position: center;

    min-height: 100vh;

    &__images,
    &__images2 {
      gap: 96px;
    }

    &__images2 {
      transform: translate(-80px, -50px);
    }

    &__image {
      width: 350px;
      max-height: 250px;
    }

    &__contact-panel,
    &__button {
      display: none;
    }
  }
}

@media screen and (min-width: $min-tablet) and (max-width: $max-tablet) {
  .portfolio {
    background-image: url("../assets/img/back21.png");

    background-size: 100%;
    background-position: left;

    min-height: 100vh;

    &__images,
    &__images2 {
      gap: 64px;
    }

    &__images2 {
      transform: translate(-30px, -50px);
    }
    &__image {
      width: 300px;
      max-height: 200px;
      object-fit: cover;
    }

    &__contact-panel,
    &__button {
      display: none;
    }
  }
}

@media screen and (min-width: $min-mobile) and (max-width: $max-mobile) {
  .portfolio {
    background-image: url("../assets/img/back21.png");

    background-size: 100%;
    background-position: left;

    min-height: 100vh;

    &__logo {
      display: none;
    }

    &__header {
      justify-content: center;
    }
    &__title {
      font: {
        size: 32px;
        weight: 600;
      }
      line-height: 40px;
      padding: 16px;
    }

    &__images,
    &__images2 {
      flex-direction: column;
      align-items: stretch;
    }

    &__image {
      width: 300px;
      max-height: 200px;
      object-fit: cover;
      box-shadow: 0px 8px 4px 1px rgba($color: #000000, $alpha: 0.25);
    }

    .two {
      transform: translateY(-30px);
      align-self: flex-end;
    }
    .three {
      transform: translateY(-60px);
    }
    .four {
      transform: translateY(-90px);
      align-self: flex-end;
    }
  }
}
</style>
