<template>
  <div class="green">
    <div class="green__card green__card_desktop">
      <div class="green__info">
        <img class="green__icon" :src="Leaf" width="70" />
        <p class="green__title">Озеленение</p>

        <p class="green__text">
          {{ text1 }}
        </p>

        <Button class="green__button" />
      </div>

      <div class="green__photos green__photos_desktop">
        <img
          class="green__logo"
          :src="MAINLOGO"
          alt="logo"
          width="120"
          @click="$emit('click', 'main')"
        />

        <div class="green__images" @click="$emit('openModal', id)">
          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              width: 100%;
              gap: 16px;
            "
          >
            <img class="green__image three" :src="OneBefore" alt="" />
            <img class="green__image two" :src="OneAfter" alt="" />
          </div>
          <img class="green__image one" :src="ThreeAfter" alt="" />
        </div>
      </div>
    </div>

    <!-- mobile -->

    <div class="green__card green__card_mobile">
      <div class="green__info green__info_mobile">
        <img class="green__icon" :src="Leaf" width="64" />
        <p class="green__title">Озеленение</p>

        <p class="green__text">
          {{ text1 }}
        </p>
      </div>

      <div class="green__photos green__photos_mobile">
        <img
          class="green__logo"
          :src="MAINLOGO"
          alt="logo"
          width="80"
          @click="$emit('click', 'main')"
        />

        <div class="green__images" @click="$emit('openModal', id)">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: flex-end;
              width: 100%;
              gap: 16px;
            "
          >
            <img class="green__image three" :src="OneBefore" alt="" />
            <img class="green__image two" :src="OneAfter" alt="" />
          </div>
          <img class="green__image one" :src="ThreeAfter" alt="" />
        </div>
      </div>
      <Button class="green__button" />
    </div>

    <!-- <ContactPanel class="green__contact-panel" /> -->
  </div>
</template>

<script>
import Leaf from "@/assets/img/greenleaf.png";
import MAINLOGO from "@/assets/img/MAINLOGO.png";
import Button from "@/components/Button.vue";

import OneAfter from "@/assets/img/green.jpg";
import OneBefore from "@/assets/img/green2.jfif";
import ThreeAfter from "@/assets/img/green3.jfif";

import ContactPanel from "@/components/ContactPanel.vue";

export default (await import("vue")).defineComponent({
  name: "Green",

  components: { Button, ContactPanel },

  emits: ["openModal"],

  data: () => ({
    id: "green",

    Leaf,
    MAINLOGO,

    OneBefore,
    OneAfter,
    ThreeAfter,

    text1: `Завершающим этапом создания сада является его озеленение.\nПосадка кустарников, деревьев, многолетних растений и газона\nпридает саду законченный вид.\nЭта работа проводится как в комплексе, в процессе\nстроительства сада, так и может быть предоставлена отдельно.`,
    text2: `Проектирование состоит из эскизного проекта и рабочей\nдокументации.`,
    text3: `Эскизный проект - разрабатывается план сада, его стилистика,\nделается визуализация 3D.`,
    text4: `Рабочая документация - разрабатываются все необходимые\nчертежи для создания сада.`,
    text5: `Перед началом проектирвоания необходим выезд на объект и\nвстреча с заказчиком.`,
  }),
});
</script>

<style lang="scss" scoped>
.green {
  background-image: url("../assets/img/greenbg.png");
  background-size: cover;

  display: grid;

  &__card {
    grid-column: 1 / -1;
    display: grid;
  }

  &__title {
    margin-top: 16px;
    color: $green-title;
    font: {
      size: 24px;
      weight: 700;
    }
    line-height: 32px;
  }
  &__text {
    text-align: center;
    margin-top: 16px;
    color: $green-text;
    font: {
      size: 16px;
      weight: 500;
    }
    line-height: 24px;
    white-space: pre-wrap;
  }
  &__images {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    cursor: pointer;
  }

  &__image {
    border-radius: 16px;
    width: 200px;
    object-fit: contain;
    box-shadow: 0px 8px 4px 1px rgba($color: #000000, $alpha: 0.25);
  }
}

@media screen and (min-width: $min-laptop-lg) {
  .green {
    grid-template-columns: $grid-laptop;
    height: 100vh;
    position: relative;

    &__logo {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    &__card {
      grid-template-columns: $grid-laptop;
      &_mobile {
        display: none;
      }
    }

    &__contact-panel {
      display: none;
    }

    &__info {
      grid-column: 1 / 7;
      display: flex;
      padding: 64px 0px 32px 0px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      gap: 16px;

      &_mobile {
        display: none;
      }
    }
    &__photos {
      grid-column: 7 / -1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      padding: 24px;
      gap: 16px;

      &_mobile {
        display: none;
      }
    }
    &__button {
      margin-top: 24px;
      width: 400px;
    }

    &__images {
      width: 100%;
      align-items: flex-end;
    }

    &__text {
      font-size: 20px;
      line-height: 20.5px;
    }

    .one {
      width: 580px;
      height: 300px;
      object-fit: cover;
    }
    .three {
      height: 306px;
      width: 220px;
      object-fit: cover;
    }
    .two {
      height: 220px;
      width: 340px;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: $min-laptop) and (max-width: $max-laptop) {
  .green {
    grid-template-columns: $grid-laptop;
    height: 100vh;
    position: relative;

    &__logo {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    &__card {
      grid-template-columns: $grid-laptop;
      &_mobile {
        display: none;
      }
    }

    &__contact-panel {
      display: none;
    }

    &__info {
      grid-column: 1 / 7;
      display: flex;
      padding: 32px 0px;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &_mobile {
        display: none;
      }
    }
    &__photos {
      grid-column: 7 / -1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      padding: 24px;
      gap: 16px;

      &_mobile {
        display: none;
      }
    }
    &__button {
      margin-top: 24px;
      width: 400px;
    }

    &__images {
      width: 100%;
      align-items: flex-end;
    }

    .one {
      width: 460px;
      height: 250px;
      object-fit: cover;
    }
    .three {
      height: 306px;
      width: 180px;
      object-fit: cover;
    }
    .two {
      height: 184px;
      width: 330px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: $max-tablet) {
  .green {
    grid-template-columns: $grid-mobile;

    &__card {
      background-color: rgba($color: $white, $alpha: 0.1);
      box-shadow: 0px 8px 10px 1px rgba($color: #000000, $alpha: 0.25);
      border-radius: 8px;
      padding: 16px;
      margin: 8px 16px;

      grid-template-columns: $grid-mobile;
      &_desktop {
        display: none;
      }
    }

    &__contact-panel {
      grid-column: 1 / -1;
    }

    &__logo {
      display: none;
    }

    &__info {
      grid-column: 1 / -1;
      display: flex;
      padding: 32px 8px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      &_desktop {
        display: none;
      }
    }
    &__photos {
      grid-column: 1 / -1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      &_desktop {
        display: none;
      }
    }
    &__button {
      margin-top: 32px;
      grid-column: 1 / -1;
    }
    &__images {
      width: 100%;
      justify-content: center;
      align-items: center;
      padding-bottom: 32px;
    }
    &__image {
      width: 120px;
    }
    .one {
      width: 250px;
      height: 150px;
      object-fit: cover;
    }
  }
}
</style>
